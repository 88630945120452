import { cn } from "@/libs/utils";

type ChipProps = {
  label: string;
  onClick?: () => void;
  onDelete?: () => void;
  className?: string;
  clickable?: boolean;
};

export const Chip = ({
  label,
  onClick,
  clickable = false,
  className,
}: ChipProps) => {
  return (
    <div
      className={cn(
        "inline-flex items-center px-3 py-1 rounded-full bg-chip text-chip-foreground",
        className,
        { 'cursor-pointer': clickable }
      )}
      onClick={onClick}
    >
      <span className="text-[12px] font-normal leading-[12px] tracking-[0.4px] font-urbanist">
        {label}
      </span>
    </div>
  );
};
