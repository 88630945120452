'use client';
import { useState } from 'react';
import { useTranslations } from 'next-intl';
import isEmpty from 'lodash/isEmpty';

import PositiveSvg from '@/libs/icons/21/positive.svg';
import NegativeSvg from '@/libs/icons/21/negative.svg';
import CheckListSvg from '@/libs/icons/38/check-list.svg';
import { SentimentEnum } from '@/libs/types';

import { Typography } from '@/components/ui/typography';
import BlockTitle from '@/components/BlockTitle';
import { Button } from '@/components/ui/button';
import TickersList from './TickersList';

interface ChartProps {
  data: Record<SentimentEnum, Record<string, number>>;
}

const Chart = ({ data }: ChartProps) => {
  const t = useTranslations();
  const [selected, setSelected] = useState<SentimentEnum>(SentimentEnum.POSITIVE);
  const isDataEmpty = isEmpty(data[SentimentEnum.NEGATIVE]) && isEmpty(data[SentimentEnum.POSITIVE]);

  return (
    <>
      <div className="flex items-center gap-4">
        <div className="grid grid-cols-12 gap-4 w-full">
          <div className="col-span-12 sm:col-span-8 md:col-span-12 lg:col-span-8 flex items-center gap-2">
            <CheckListSvg />
            <BlockTitle marker={t('Common.markerAi')} title={t('NewsSection.charts.cartTitleTopStocks')} />
          </div>
          {!isDataEmpty && (
            <div className="col-span-12 sm:col-span-4 md:col-span-12 lg:col-span-4 flex gap-4 justify-center">
              <Button
                variant="ghost"
                disabled={selected === SentimentEnum.POSITIVE}
                onClick={() => setSelected(SentimentEnum.POSITIVE)}
              >
                <Typography variant="caption" className="text-primary-foreground">
                  {t('Sentiments.positive')}
                </Typography>
              </Button>
              <Button
                variant="ghost"
                disabled={selected === SentimentEnum.NEGATIVE}
                onClick={() => setSelected(SentimentEnum.NEGATIVE)}
              >
                <Typography variant="caption" className="text-primary-foreground">
                  {t('Sentiments.negative')}
                </Typography>
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="w-full px-2 sm:px-7 pb-2">
        {!isDataEmpty && (
          <div className="flex items-center gap-1 pt-3">
            <Typography variant="body1">
              {SentimentEnum.POSITIVE === selected
                ? t('NewsSection.charts.cartTitleTopStocksPositive')
                : t('NewsSection.charts.cartTitleTopStocksNegative')}
            </Typography>
            {SentimentEnum.POSITIVE === selected ? <PositiveSvg /> : <NegativeSvg />}
          </div>
        )}

        <TickersList selected={selected} data={data} />
      </div>
    </>
  );
};

export default Chart;
