import { PropsWithChildren } from 'react';

import { Typography, TypographyVariants } from '@/components/ui/typography';

import Marker from './Marker';
import { cn } from '@/libs/utils';

interface BlockTitleProps {
  title: string;
  marker?: string;
  variant?: TypographyVariants;
  isTop?: boolean;
}

const BlockTitle = ({ children, title, marker, variant = 'h6', isTop = true }: BlockTitleProps & PropsWithChildren) => {
  return (
    <div className={cn("flex flex-row gap-1", { 'items-center': !isTop })}>
      <Typography variant={variant}>
        {title}
      </Typography>
      {children}
      {marker && <Marker text={marker} isTop={isTop} />}
    </div>
  );
};

export default BlockTitle;
