import React, { useState } from 'react';
import { isToday, format, parseISO } from 'date-fns';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { ArrowDownRight, ArrowUpRight } from "lucide-react";

import { cn } from '@/libs/utils';
import { Analysis } from '@/libs/types';
import { Card, CardHeader, CardDescription, CardFooter, CardContent } from '@/components/ui/card';
import { Typography } from '@/components/ui/typography';
import { Progress } from '@/components/ui/progress';
import { Chip } from '@/components/ui/chip';

const sentimentColorMap: Record<Analysis['sentiment'], string> = {
  POSITIVE: 'bg-positive',
  NEGATIVE: 'bg-negative',
  NEUTRAL: 'bg-neutral',
};

interface AnalysisCardProps {
  item: Analysis;
}

const CHAR_LIMIT = 200;

const AnalysisCard = ({ item }: AnalysisCardProps) => {
  const t = useTranslations();

  const [isExpanded, setIsExpanded] = useState(false);
  const content = item.analysis;

  const displayContent = isExpanded || content.length <= CHAR_LIMIT ? content : `${content.substring(0, CHAR_LIMIT)}...`;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const releaseDate = parseISO(item.relatedDate?.split('T')?.[0] ?? item.relatedDate)
  
  return (
    <Card className="px-8 py-6">
      <CardHeader>
        <div className="flex item-start md:items-center flex-col md:flex-row">
          <Typography variant="h5">
            {isToday(releaseDate) ? t('AnalysisSection.cardTodayDate') : format(releaseDate, 'dd MMMM yyyy')}
          </Typography>
          <div className="flex items-center md:ml-auto gap-3 justify-between md:justify-start pt-2 md:pt-0">
            <div className="flex items-center gap-2 h-full">
              <div className={cn("w-[6px] h-[6px] rounded-full", sentimentColorMap[item.sentiment] )} />
              <Typography variant="subtitle2">
                {t(`Sentiments.${item.sentiment.toLowerCase()}`)}
              </Typography>
            </div>
            <div className="h-[15px] bg-divider w-[1px] hidden md:block" />
            <div className="flex flex-col items-center">
              <Typography variant="subtitle2">
                {t('AnalysisSection.cardImportance', { importance: item.importance })}
              </Typography>
              <div className="w-full">
                <Progress value={item.importance * 10} className="h-[6px] w-[100px]" />
              </div>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardContent className="my-5">
        <CardDescription>
          {displayContent}
          {content.length > CHAR_LIMIT && (
            <>
              <Typography
                as="span"
                variant='body1'
                className="cursor-pointer inline-flex items-center ml-[7px] text-primary"
                onClick={toggleExpand}
              >
                {isExpanded ? t('AnalysisSection.cardLess') : t('AnalysisSection.cardMore')}
                {isExpanded ? (
                  <ArrowUpRight className="ml-1 w-[16px] h-[16px]" />
                ) : (
                  <ArrowDownRight className="ml-1 w-[16px] h-[16px]" />
                )}
              </Typography>
            </>
          )}
        </CardDescription>
      </CardContent>
      <CardFooter>
      <div className="flex flex-wrap gap-2">
          {!!item.category && (
            <Link key={`category-link-${item.category}-${item.id}`} href="/" passHref>
              <Chip
                className="h-8"
                label={t('AnalysisSection.cardChipCategory', { name: t(`Categories.${item.category}`) })}
                clickable
              />
            </Link>
          )}
          {!!item.sector && (
            <Link key={`sector-link-${item.sector}-${item.id}`} href={`/sectors/${item.sector}`} passHref>
              <Chip
                className="h-8"
                label={t('AnalysisSection.cardChipSector', { name: t(`Sectors.${item.sector}`) })}
                clickable
              />
            </Link>
          )}
          {item.tyckerSymbol && (
            <Link href={`/tickers/${item.tyckerSymbol}`} passHref>
              <Chip
                className="h-8"
                label={t('AnalysisSection.cardChipTicker', { name: item.tyckerSymbol })}
                clickable
              />
            </Link>
          )}
        </div>
      </CardFooter>
    </Card>
  );
};

export default AnalysisCard;
