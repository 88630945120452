import { useTranslations } from 'next-intl';

import { Typography } from '@/components/ui/typography';

const NoData = () => {
  const t = useTranslations('NewsSection.charts');

  return (
    <div className="flex h-full justify-center mr-[50px]">
      <Typography variant="caption" className="m-auto">{t('chartNoData')}</Typography>
    </div>
  );
};

export default NoData;