import { Typography } from '@/components/ui/typography';
import { cn } from '@/libs/utils';

interface MarkerProps {
  text: string;
  isTop?: boolean;
}

const Marker = ({ text, isTop = true }: MarkerProps) => {
  return (
    <div className={cn("w-5 h-5 rounded-md bg-[#D5E26E] flex items-center justify-center", {
      "mt-[-5px]": isTop
    })}>
      <Typography variant="caption" className="font-[600] text-primary dark:text-secondary">
        {text}
      </Typography>
    </div>
  );
};

export default Marker;
