import React from 'react';
import { useTranslations } from 'next-intl';

import { SentimentEnum } from '@/libs/types';

import { Typography } from '@/components/ui/typography';
import { Tooltip } from '@/components/ui/tooltip';
import { cn } from '@/libs/utils';

interface TickersListProps {
  data: Record<SentimentEnum, Record<string, number>>;
  selected: SentimentEnum;
}

const TickersList = ({ selected, data }: TickersListProps) => {
  const t = useTranslations('NewsSection.charts');
  const selectedData = Object.entries(data[selected]);

  if (!selectedData.length) {
    return (
      <div className="flex flex-col items-center justify-center w-full py-8 text-center">
        <Typography variant="subtitle1">
          {t('chartNoData')}
        </Typography>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full py-2 space-y-2">
      {selectedData.map(([symbol, value]) => {
        const fixedValue = Number(value.toFixed(2));

        return (
          <div key={`LineChartHorizontal-${selected}-${symbol}-${value}`} className="flex items-center space-x-2">
            <div className="min-w-[15%]">
              <Typography variant="subtitle2" className="flex-1">
                {symbol}
              </Typography>
            </div>
            <Tooltip
              text={selected === SentimentEnum.POSITIVE
                ? t('cartTooltipTopStocksPositive', { symbol, value: fixedValue })
                : t('cartTooltipTopStocksNegative', { symbol, value: fixedValue })}
            >
              <div className="flex-4 w-full py-1">
                <div className="relative w-full h-1 bg-gray-200 rounded-full">
                  <div
                    className={cn("absolute top-0 left-0 h-full rounded-full", {
                      'bg-positive': selected === SentimentEnum.POSITIVE,
                      'bg-negative': selected === SentimentEnum.NEGATIVE,
                    })}
                    style={{ width: `${fixedValue}%` }}
                  />
                </div>
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default TickersList;
