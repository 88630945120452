'use client'
import { useEffect, useState } from 'react';
import { cn } from '@/libs/utils';

import NoData from './NoData';
import { colors } from './constants';

interface BarChartProps {
  data: Array<[string, number]>;
}

const BarChart = ({ data }: BarChartProps) => {
  const max = Math.max(...data.map(([_, value]) => value));

  const [heights, setHeights] = useState(Array(data.length).fill(0));
  const [labelsVisible, setLabelsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHeights(data.map(([_, value]) => (value / max) * 100));
    }, 0);

    const labelTimer = setTimeout(() => {
      setLabelsVisible(true);
    }, 300);

    return () => {
      clearTimeout(timer);
      clearTimeout(labelTimer);
    };
  }, [max, data]);
  
  if (data.every(([_, value]) => !value)) {
    return <NoData />;
  }
  
  return (
    <div className="flex justify-around items-end h-full space-x-1">
      {data.map(([label, value], index) => value ? (
        <div
          key={index}
          className={cn(
            "w-[44px] min-h-[15px] flex items-start justify-center rounded-[16px] transition-all duration-300 ease-in-out text-[0.75rem]",
            {
              'pt-2': heights[index] > 15,
              'pt-0': heights[index] <= 15,
            }
          )}
          style={{
            height: `${heights[index]}%`,
            backgroundColor: colors[label],
          }}
        >
          {labelsVisible && (
            <div
              className={cn({
                "my-auto": heights[index] <= 15,
                "my-0": heights[index] > 15,
              })}
            >
              {labelsVisible ? `${value}%` : ''}
            </div>
          )}
        </div>
      ) : null)}
    </div>
  );
};

export default BarChart;
