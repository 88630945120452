import { useTranslations } from "next-intl";

import { News as NewsModel } from "@/libs/types";
import { Typography } from '@/components/ui/typography';
import { Button } from '@/components/ui/button';
import ClockSvg from '@/libs/icons/14/clock.svg';

import NewsTime from './Time';

interface NewsCardProps {
  item: NewsModel;
}

const NewsCard = ({ item }: NewsCardProps) => {
  const t = useTranslations();
  
  return (
    <div className="py-4">
      <div className="flex flex-row items-center pb-4">
        <ClockSvg />
        <NewsTime publishTime={item.publishTime} />
      </div>

      <Typography variant="subtitle1" className="font-[500]">{item.title}</Typography>

      <div className="flex flex-row py-4 gap-2">
        {item.link && (
          <Button
            variant="link"
            onClick={() => item.link && global.open(item.link, '_blank')}
            className="text-progress-foreground p-0"
          >
            {t('Common.link')}
          </Button>
        )}
      </div>

      <hr className="border-t" />
    </div>
  );
};

export default NewsCard;