'use client'
import { usePathname, useSearchParams , useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';

import { useDispatchPageLoader } from '@/components/PageLoader';
import { Button } from '@/components/ui/button';
import React from 'react';
import { NewsSortEnum } from '@/libs/types';
import { cn } from '@/libs/utils';

const NEWS_SORT: Record<NewsSortEnum, string> = {
  [NewsSortEnum.DAY]: 'day',
  [NewsSortEnum.WEEK]: 'week',
  [NewsSortEnum.MONTH]: 'month',
  [NewsSortEnum.YEAR]: 'year',
}

const sortMap = Object.keys(NewsSortEnum) as Array<keyof typeof NewsSortEnum>;

interface SortProps {
  sort: NewsSortEnum;
  symbol?: string;
}

const Sort = ({ sort, symbol }: SortProps) => {
  const t = useTranslations('NewsSection.filter');

  const setLoader = useDispatchPageLoader();

  const searchParams = useSearchParams() ?? '';
  const pathname = usePathname();
  const router = useRouter();

  const params = new URLSearchParams(searchParams);
 
  const onSortChange = (sort: string) => {
    setLoader(true);

    params.set('sort', sort);

    router.push(`${pathname}?${params.toString()}`);
  }
  
  return (
    <div className="flex flex-row ml-0 sm:ml-auto gap-5">
      {sortMap.filter(key => !(NEWS_SORT[key] === NEWS_SORT.YEAR && !symbol)).map((key) => {
        const value = t(NEWS_SORT[key]);

        return (
          <Button
            key={key}
            disabled={sort === NewsSortEnum[key]}
            variant="ghost"
            size="sm"
            className={cn(
              "text-[12px]",
              { 
                "text-primary-foreground bg-chip rounded-[10px]": sort === NewsSortEnum[key],
              }
            )}
            
            onClick={() => onSortChange(key)}
          >
            {value}
          </Button>
        );
      })}
    </div>
  );
};

export default Sort;