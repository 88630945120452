'use client';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Title, ChartOptions, Filler, ChartData } from 'chart.js';

import { colors } from '../constants';
import { NewsSortEnum } from '@/libs/types';
import { useTranslations } from 'next-intl';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Title, Filler);

interface LineChartProps {
  difference: number;
  data: {
    labels: string[];
    prices: number[];
  };
  sort: NewsSortEnum;
}

const currentYear = new Date().getFullYear();

const LineChart = ({ data, difference, sort }: LineChartProps) => {
  const t = useTranslations('NewsSection.charts');

  const chartData: ChartData<'line'> = {
    labels: data.labels,
    datasets: [
      {
        label: t('cartLabelHistoricalTickerPrices'),
        data: data.prices,
        borderColor: difference > 0 ? colors.POSITIVE : colors.NEGATIVE,
        backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              return difference > 0 ? 'rgba(190, 232, 190, 0.5)' : 'rgba(248, 164, 161, 0.5)';
            }

            const colorStart = difference > 0 ? 'rgba(190, 232, 190, 0.5)' : 'rgba(248, 164, 161, 0.5)';
            const colorEnd = difference > 0 ? 'rgba(190, 232, 190, 0)' : 'rgba(248, 164, 161, 0)';
          
            const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
            gradient.addColorStop(0, colorStart);
            gradient.addColorStop(1, colorEnd);
            return gradient;
          },
        fill: true,
        tension: 0.4,
        pointRadius: 0,
      },
    ]
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: false,
        }
      },
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: '#333',
        titleColor: '#fff',
        bodyColor: '#fff',
        titleFont: { size: 15 },
        bodyFont: { size: 14 },
        callbacks: {
          title: (context) => {
            const label = context[0].label;
  
            const monthIndex = data.labels.indexOf(label);
            const decemberIndex = data.labels.indexOf('Dec');

            if (sort === NewsSortEnum.YEAR) {
              return label;
            }
  
            return `${label} ${decemberIndex >= monthIndex ? currentYear : currentYear - 1}`;
          },
          label: (context) => {
            return `${context.parsed.y}: ${context.dataset.label}`;
          }
        }
      }
    }
  };

  return (
    <Line data={chartData} options={options} />
  );
};

export default LineChart;
