'use client'
import { useTranslations } from 'next-intl';

import { CategoryEnum, CategorySectorEnum } from '@/libs/types';
import { useAnalysisSuspenseInfiniteQuery } from '@/libs/api';
import { Button } from '@/components/ui/button';

import Card from './Card';

interface AnalysisListProps {
  symbol?: string;
  sector?: CategorySectorEnum;
  category?: CategoryEnum;
}

const AnalysisList = (params: AnalysisListProps) => {  
  const t = useTranslations();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAnalysisSuspenseInfiniteQuery(params);

  const handleMoreClick = () => {
    fetchNextPage();
  };

  return (
    <div className="pt-5 space-y-6">
      {data.entities.map((item) => (
        <Card key={item.id} item={item} />
      ))}
      {hasNextPage && (
        <Button variant="ghost" fullWidth onClick={handleMoreClick} disabled={isFetchingNextPage}>
          {t('Common.more')}
        </Button>
      )}
    </div>
  );
};

export default AnalysisList;
